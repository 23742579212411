nav {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 0.5rem 1rem;
	background-color: black;
	color: #fff;
	box-shadow: 0 0 4px black;

	.logo {
		font-size: 26px;
	}

	svg {
		font-size: 30px;
		border: 2px #fff solid;
		border-radius: 5px;
		margin-left: 0.75rem;
		padding: 0.25rem;
		transition: 0.1s ease;
		cursor: pointer;

		&:hover {
			transform: scale(1.15);
			background-color: #fff;
			color: #000;
			border-color: black;
		}
	}
}
