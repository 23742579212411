*,
*::before,
*::after {
	box-sizing: border-box;
}

h1, h2, h3, h4 {
    margin: 0;
    padding: 0;
}

.app {
}
