.ReactModal__Overlay {
	z-index: 100;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	background-color: rgba(230, 230, 230, 0.384);
	transition: opacity 0.3s ease-in-out;

	&--after-open {
		opacity: 1;
	}

	&--before-close {
		opacity: 0;
	}
}

.modal {
	$modal-padding: 1.5rem;

	z-index: 101;
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 2.5%;
	bottom: 2.5%;
	left: 50%;
	transform: translateX(-50%);
	width: 95%;
	max-width: 700px;
	padding: $modal-padding;
	background-color: #fff;
	border: 1px rgb(223, 223, 223) solid;
	border-radius: 5px;
	overflow-y: auto;
	outline: none;

	.modal-exit {
		position: fixed;
		top: $modal-padding;
		right: $modal-padding;
		font-size: 26px;
		color: red;
		border: 1px #000 solid;
		border-radius: 5px;
		padding: 0.25rem;
		transition: 0.2s ease;
		cursor: pointer;

		&:hover {
			transform: scale(1.15);
			background-color: #000;
			color: #fff;
			border-color: #000;
		}
	}

	.modal-content {
		p {
			margin-bottom: 1rem;
		}



        img {
            display: block;
            max-width: 70%;
        }

		.divider-horizontal {
			display: block;
			margin-top: 0.5rem;
			margin-bottom: 1rem;
			height: 1px;
			width: 100%;
			background-color: rgb(148, 148, 148);
		}
	}
}

.help-content {
    ul {
        li.tip, img {
            margin-bottom: 1rem;
        }

        img {
            margin-inline: auto;
        }
    }
}

.keybindings {
	display: grid;
	grid-template-columns: 1fr 1fr;

	@media screen and (max-width: 560px) {
		grid-template-columns: 1fr;
	}

	.keybinding {
		display: flex;
		align-items: center;
		line-height: 0;
		margin-bottom: 0.75rem;

		&-text {
			&::before {
				content: '- ';
				margin-left: 0.25rem;
				font-size: 20px;
				font-weight: 500;
			}
		}

		.key {
			$key-width: 1.75em;

			display: inline-flex;
			align-items: center;
			justify-content: center;
			position: relative;
			font-size: 16px;
			font-weight: 600;
			padding: 0.25em;
			margin-right: 0.25rem;
			min-width: $key-width;
			max-width: fit-content;
			height: $key-width;
			border: 2px rgb(220, 220, 220) solid;
			border-radius: 2px;
			box-shadow: 2px 2px 1px 1px rgb(148, 148, 148);
		}
	}
}

.changes-content {
	color: #333;
	background-color: rgb(245, 245, 245);
	border: 1px rgb(199, 199, 199) solid;
	border-radius: 5px;
	padding: 1rem;
	max-height: 15rem;
	overflow-y: scroll;
}
