.fretboard-wrapper {
	grid-area: fretboard;
	$fret-min-width: 2.5rem;
	$fret-width: 3.5rem;
	$fret-height: 1.75rem;
	$fret-gap: 3px;

	overflow-x: auto;
	display: flex;
	padding-bottom: 1rem;

	.fretboard {
		border-radius: 5px 5px 23px 0;
		position: relative;
		background: radial-gradient(72.66% 49.73% at 81.86% 50.27%, #46240f 0%, #0d0601 100%);
		box-shadow: -10px 4px 6px black;

		.fretboard-labels {
			display: flex;
			gap: $fret-gap;
			padding: 0.25rem 0;

			.first {
				min-width: $fret-min-width;
				width: $fret-width;
			}

			span {
				text-align: center;
				font-weight: 600;
				min-width: $fret-min-width;
				width: $fret-width;
				color: white;
			}

			.tunings-selector {
				z-index: 5;
				min-width: $fret-min-width;
				width: $fret-width;
				background-color: #0d0601;
				color: #fff;
				border: none;
			}
		}

		.fretboard-string {
			position: relative;
			display: flex;
			height: $fret-height;
			gap: $fret-gap;

			.tuning-selector {
				z-index: 5;
				min-width: $fret-min-width;
				width: $fret-width;
				background-color: #0d0601;
				color: #fff;
				border: none;
			}

			.string-line {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				min-width: 100%;
				width: max-content;
				background-color: #e4e5e0;

				&-0 {
					height: 3px;
				}
				&-1 {
					height: 2.5px;
				}
				&-2 {
					height: 2px;
				}
				&-3 {
					height: 1.5px;
				}
				&-4 {
					height: 1px;
				}
				&-5 {
					height: 0.5px;
				}
			}

			.fretboard-btn {
				z-index: 1;
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 14px;
				font-weight: 600;
				text-shadow: 1px 1px 5px black;
				background-color: rgba(203, 213, 225, 0.14);
				color: #efefef;
				min-width: $fret-min-width;
				width: $fret-width;
				height: $fret-height;
				user-select: none;
				cursor: pointer;

				.fret-dot {
					z-index: -1;
					position: absolute;
					transform: translateY(50%);
					width: calc($fret-height - 2px);
					height: calc($fret-height - 2px);
					border-radius: 50%;
					background-color: #e4e5e0;

					&.middle {
						transform: none;
					}
				}

				&:hover {
					background-color: transparent;
				}
			}
		}
	}
}
