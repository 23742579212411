.controller-wrapper {
    //z-index: -10;
    display: block;
    padding-top: 2.5rem;
    margin-top: -2.5rem;
    overflow-x: auto;
}

.controller-scroll {
    min-width: fit-content;
    width: auto;
	background-color: #0d0601;
}

.controller {
	display: flex;
	gap: 0.5rem;
	min-width: fit-content;
	width: 100%;
	background-color: #0d0601;
	padding: 0.4rem;
	align-self: flex-end;

    label {
        color: #fff;
        align-self: center;
        white-space: nowrap;
    }

    .checkbox {
        align-self: center;
    }

	.divider {
		width: 1px;
		align-self: stretch;
		background-color: #fff;
		margin: 0 0.25rem;
	}

	.chord-selector {
		z-index: 1;
		max-width: 10rem;
		background-color: #0d0601;
		color: #fff;
		border-color: #fff;
		text-align: center;
	}

	.chord-name-input {
		background-color: #0d0601;
		color: #fff;
		border: 1px #fff solid;
		padding: 0 0.25rem;
		margin-left: 1rem;
	}

	.control-button {
        z-index: 10;
		position: relative;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		justify-content: center;
		aspect-ratio: 1 / 1;
		width: 2rem;
		height: 2rem;
		background-color: #372b24;
		border-radius: 4px;
		color: #fff;
		font-size: 18px;
		user-select: none;
		transition: 0.1s ease;
		cursor: pointer;

		&.toggled {
			background-color: #647894;
		}

		svg,
		.icon {
			width: 20px;
			height: 20px;
		}

		.icon {
			width: 28px;
			height: 28px;
			filter: invert(1);
		}

		.tooltip {
			z-index: 10;
            background-color: #fff;
			color: #000;
			width: max-content;
			padding: 0.25rem;
            border: 1px #000 solid;
			border-radius: 6px;
            border-bottom-left-radius: 0px;
			position: absolute;
            left: 0px;
			bottom: 2.25rem;
            pointer-events: none;
			opacity: 0;
			transition: 0.2s ease;
		}

		&:hover {
			background-color: #475569;
			transform: scale(1.05);

			.tooltip {
				opacity: 1;
			}
		}
	}
}
