@import url('https://fonts.googleapis.com/css2?family=Nanum+Gothic+Coding&display=swap');
.tab-wrapper {
	display: contents;
	width: fit-content;
	max-width: 90vw;
}

.tab {
	display: flex;
	flex-direction: column;
	max-width: 100%;
	max-height: 40vh;
	overflow-x: auto;
	overflow-y: auto;
	padding: 1rem;
	border: 1px #767676 solid;
	user-select: none;
	font-family: 'Nanum Gothic Coding', monospace;

	$note-width: 8px;
	$note-height: 12px;
	$note-font-size: 12px;

	&-line {
		display: flex;
		width: fit-content;
		margin: 0.5rem 0;
		box-shadow: 1px 2px 7px 0px #9b9b9b;
		padding: 0.4rem 1rem 0.4rem 0.5rem;
		border-radius: 4px 0 0 4px;

		.tunings {
			display: flex;
			flex-direction: column;
			font-weight: 900;
			width: $note-width;
			margin-right: 8px;
			letter-spacing: 2px;

			div {
				height: $note-height;
				font-size: $note-font-size;
				text-align: center;
				font-weight: 600;
			}
		}

		.columns {
			display: flex;
			color: #767676;

			&:hover {
				background-color: rgb(156, 156, 156);
			}

			&.selected {
				background-color: black;
				color: white;
			}

			.tab-column {
				display: flex;
				flex-direction: column;
				width: $note-width;
				cursor: pointer;

				.note {
					height: $note-height;
					font-size: $note-font-size;
					text-align: center;
					font-weight: 400;
				}
			}
		}
	}
}

.copy-tab {
	color: blue;
	font-weight: 500;
	width: fit-content;
	user-select: none;
	cursor: pointer;

	&:hover {
		text-decoration: underline;
	}
}
