.tab-maker {
	position: relative;
	display: grid;
	grid-template-areas:
		'fretboard'
		'tab';
	grid-auto-rows: min-content min-content min-content;
	max-width: 1400px;
	padding: 1rem;
}
